



























































import { Component, Watch, Vue } from 'vue-property-decorator';
import { IMessagesStatistics } from '@/interfaces';
import { format, startOfMonth } from 'date-fns';
import { dispatchGetMessagesStatistics } from '@/store/admin/accessors';

@Component
export default class MessagesStatistics extends Vue {
  public loading: boolean = true;
  public statistics: IMessagesStatistics[] = [];
  public headers = [
    {
      text: this.$vuetify.lang.t('$vuetify.sender'),
      value: 'sender',
    },
    {
      text: this.$vuetify.lang.t('$vuetify.service'),
      value: 'service',
    },
    {
      text: this.$vuetify.lang.t('$vuetify.new'),
      value: 'new',
    },
    {
      text: this.$vuetify.lang.t('$vuetify.queued'),
      value: 'queued',
    },
    {
      text: this.$vuetify.lang.t('$vuetify.sent'),
      value: 'sent',
    },
    {
      text: this.$vuetify.lang.t('$vuetify.delivered'),
      value: 'delivered',
    },
    {
      text: this.$vuetify.lang.t('$vuetify.read'),
      value: 'read',
    },
    {
      text: this.$vuetify.lang.t('$vuetify.error'),
      value: 'error',
    },
  ];
  public menu: boolean = false;
  public today = new Date();
  public range = [format(startOfMonth(this.today), 'yyyy-MM-dd'), format(this.today, 'yyyy-MM-dd')];
  public presets = [
    {
      name: this.$vuetify.lang.t('$vuetify.thisMonth'),
      dates: { start: this.range[0], end: this.range[0] },
    },
  ];

  public async mounted() {
    await this.updateMessagesStatistics();
  }

  public async updateMessagesStatistics() {
    this.menu = false;
    this.loading = true;
    this.statistics = await this.getDataFromApi();
    this.loading = false;
  }

  public async getDataFromApi() {
    return await dispatchGetMessagesStatistics(this.$store, {
      from_date: this.range[0],
      to_date: this.range[1],
    });
  }

}
